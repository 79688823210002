.shell__navigation,
.shell__header,
.shell__content > ata-app-footer,
ata-page-header {
  display: none !important;
}

html {
  zoom: 50%;
}

html, body {
  height: auto;
}
